import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { InterfaceToastItem } from '../toaster.model';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'ds24-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() toastItem?: InterfaceToastItem = {};
  @Output() readonly toastClose = new EventEmitter();

  #closingTimerSubscription: Subscription;

  constructor() {}

  ngOnInit() {
    if (this.toastItem.duration) {
      this.#closingTimerSubscription = timer(this.toastItem.duration).subscribe(() => {
        this.closeAction(null);
      });
    }
  }

  closeAction(action: string) {
    this.toastClose.emit();
    /* istanbul ignore else */
    if (this.toastItem.afterCloseSubject) {
      this.toastItem.afterCloseSubject.next(action);
      this.toastItem.afterCloseSubject.complete();
    }
  }

  ngOnDestroy() {
    if (this.#closingTimerSubscription) {
      this.#closingTimerSubscription.unsubscribe();
    }
  }
}
