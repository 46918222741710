import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DataIdCacheState,
  GENERIC_DATA_ENDPOINT,
  GenericDataState,
  GenericDataTypeKey,
} from './generic.model';

const featureSelector = createFeatureSelector<GenericDataState>('genericData');
const selectListState = createSelector(featureSelector, (state) => state.list);
const selectByIdState = createSelector(featureSelector, (state) => state.byId);

export function selectDataPropertyFactory<T>(
  statePath: string[],
  fallback?: T
) {
  return createSelector(selectListState, (state) => {
    const statePathCopy = statePath.slice(0);
    let stateSlice = state;
    while (statePathCopy.length > 0) {
      stateSlice =
        stateSlice[statePathCopy.shift()] ||
        (statePathCopy.length > 0 ? ({} as any) : fallback);
    }
    return stateSlice as any as T;
  });
}

export function selectDataIdCachePropertyFactory<T>(
  dataType: GenericDataTypeKey,
  id: string,
  statePath: string[],
  fallback?: T
) {
  statePath.unshift(GENERIC_DATA_ENDPOINT + dataType + '/' + id);
  return createSelector(
    selectByIdState,
    (state: { [refId: string]: DataIdCacheState }): T => {
      const statePathCopy = statePath.slice(0);
      let tmp: { [refId: string]: DataIdCacheState } | DataIdCacheState | T =
        state;

      while (statePathCopy.length > 0) {
        tmp = tmp[statePathCopy.shift()];
        if (typeof tmp === 'undefined') {
          tmp = fallback;
          if (statePathCopy.length > 0) {
            tmp = {};
          }
        }
      }
      return tmp as T;
    }
  );
}

export function selectGenericDataById<T>(
  dataType: GenericDataTypeKey,
  id: string | number
) {
  return selectGenericDataByRefId(GENERIC_DATA_ENDPOINT + dataType + '/' + id);
}

export const selectGenericDataByIdData = <T>(
  dataType: GenericDataTypeKey,
  id: string | number
) =>
  createSelector(
    selectGenericDataById(dataType, id),
    (state: DataIdCacheState) => {
      return (state && state.data ? state.data : undefined) as unknown as T;
    }
  );

export const selectGenericDataByRefId = (refId: string) =>
  createSelector(
    selectByIdState,
    (state: { [refId: string]: DataIdCacheState }) => {
      return state[refId];
    }
  );

export const selectGenericDataByRefIdData = <T>(refId: string) =>
  createSelector(selectGenericDataByRefId(refId), (state: DataIdCacheState) => {
    return (state && state.data ? state.data : undefined) as unknown as T;
  });
