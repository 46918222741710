import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export function formMarkAllAs(
  form: UntypedFormGroup | UntypedFormArray,
  markFunction: (control: AbstractControl) => void
) {
  Object.keys(form.controls).forEach((field) => {
    const control: AbstractControl = form.controls[field];
    if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
      formMarkAllAs(control, markFunction);
      return;
    }
    markFunction(control);
  });
}

export function formMarkAllAsTouched(form: UntypedFormGroup | UntypedFormArray) {
  formMarkAllAs(form, (control) => {
    control.updateValueAndValidity({ onlySelf: false });
    control.markAsTouched();
  });
}

export function formMarkAllAsDirty(form: UntypedFormGroup | UntypedFormArray) {
  formMarkAllAs(form, (control) => {
    control.updateValueAndValidity({ onlySelf: false });
    control.markAsDirty();
  });
}
