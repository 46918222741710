import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep, merge } from 'lodash-es';
import * as OrderformActions from './orderform.actions';
import { OrderformOrderOrderState, OrderformOrderState, OrderformState } from './orderform.models';
import { objectDifference } from '../misc/object-difference';

export const ORDERFORM_FEATURE_KEY = 'orderform';

export interface OrderformPartialState {
  readonly [ORDERFORM_FEATURE_KEY]: OrderformState;
}

export const initialState: OrderformState = {
  order: {
    order: {} as OrderformOrderOrderState,
  } as OrderformOrderState,
  paymentUrl: '',
  pending: false,
  submit: 'initial',
};

const orderformReducer = createReducer(
  initialState,
  on(OrderformActions.updateOrderformRequest, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        execute: {
          ...state.order.execute,
          action: 'select',
        },
        requestedOrder: cloneDeep(state.order.order),
      },
      pending: true,
    };
  }),
  on(OrderformActions.validateOrderformRequest, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        execute: {
          ...state.order.execute,
          action: 'check',
        },
      },
      pending: true,
    };
  }),
  on(OrderformActions.sendOrderFormRequest, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        execute: {
          ...state.order.execute,
          action: 'submit',
        },
      },
      paymentUrl: '',
      pending: true,
      submit: 'pending',
    };
  }),
  on(OrderformActions.sendOrderFormRequestValidationError, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        ...data,
      },
      pending: false,
      submit: 'failed',
    };
  }),
  on(
    OrderformActions.sendOrderFormRequestError,
    OrderformActions.sendOrderFormResponseShowMessage,
    OrderformActions.formValidationFailedAfterSubmit,
    (state) => {
      return <OrderformState>{
        ...state,
        submit: 'failed',
      };
    }
  ),
  on(OrderformActions.sendOrderFormRequestPaymentUrlResponse, (state, { url }) => {
    return <OrderformState>{
      ...state,
      pending: false,
      submit: 'success',
      paymentUrl: url,
    };
  }),
  on(OrderformActions.sendOrderFormRequestRedirect, (state) => {
    return <OrderformState>{
      ...state,
      pending: false,
      submit: 'success',
    };
  }),
  on(OrderformActions.updateOrderformSuccess, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        ...data,
        order: merge(cloneDeep(data.order), objectDifference(state.order.order, state.order.requestedOrder)),
        requestedOrder: {},
      },
      pending: false,
    };
  }),
  on(OrderformActions.validateOrderformRequestSuccess, (state, { data }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        ...data,
      },
      pending: false,
    };
  }),
  on(
    OrderformActions.validateOrderformRequestError,
    OrderformActions.updateOrderformError,
    OrderformActions.sendOrderFormRequestError,
    OrderformActions.beforeSubmitOrderformFailed,
    OrderformActions.beforeSubmitOrderformSuccess,
    OrderformActions.afterSubmitOrderFormFailed,
    OrderformActions.sendOrderFormResponseShowMessage,
    (state, {}) => {
      return <OrderformState>{
        ...state,
        pending: false,
      };
    }
  ),

  on(OrderformActions.setToOrderStore, (state, { update }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        order: {
          ...state.order.order,
          ...cloneDeep(update),
        },
      },
    };
  }),

  on(OrderformActions.updateConfigProductId, (state, { productId }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        settings: {
          ...state.order.settings,
          config: {
            ...state.order.settings.config,
            product_id: productId,
          },
        },
      },
    };
  }),

  on(OrderformActions.updateProductQuantity, (state, { product_id, quantity }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        order: {
          ...state.order.order,
          items: {
            ...state.order.order.items,
            [product_id]: {
              ...state.order.order.items[product_id],
              quantity: {
                ...state.order.order.items[product_id].quantity,
                value: quantity,
              },
            },
          },
        },
      },
    };
  }),

  on(OrderformActions.updateProductVariantId, (state, { product_id, variant_id }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        order: {
          ...state.order.order,
          items: {
            ...state.order.order.items,
            [product_id]: {
              ...state.order.order.items[product_id],
              variant_id,
            },
          },
        },
      },
    };
  }),

  on(OrderformActions.updateProductETicketId, (state, { product_id, eticket_date_id }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        order: {
          ...state.order.order,
          items: {
            ...state.order.order.items,
            [product_id]: {
              ...state.order.order.items[product_id],
              eticket_date_id,
            },
          },
        },
      },
    };
  }),

  on(OrderformActions.updateProductCustomForm, (state, { product_id, forms }) => {
    return <OrderformState>{
      ...state,
      order: {
        ...state.order,
        order: {
          ...state.order.order,
          items: {
            ...state.order.order.items,
            [product_id]: {
              ...state.order.order.items[product_id],
              customForm: forms,
            },
          },
        },
      },
    };
  }),

  on(OrderformActions.checkDoubleOrderResponse, (state, { data }) => {
    if (data.type === 'hidden') {
      return state;
    }
    return {
      ...state,
      order: {
        ...state.order,
        messages: {
          ...state.order.messages,
          notifications: [data],
        },
      },
    };
  }),

  on(OrderformActions.afterMessageShown, (state, { messageType, message }) => {
    const list = state.order.messages[messageType].slice(0);
    const index = list.indexOf(message);
    if (index < 0) {
      return state;
    }
    list.splice(index, 1);
    return {
      ...state,
      order: {
        ...state.order,
        messages: {
          ...state.order.messages,
          [messageType]: list,
        },
      },
    };
  }),

  on(OrderformActions.removeValidationError, (state, { field }) => {
    const errors_by_postname = cloneDeep(state.order.validation.errors_by_postname);
    delete errors_by_postname[field];
    return {
      ...state,
      order: {
        ...state.order,
        validation: {
          ...state.order.validation,
          errors_by_postname,
        },
      },
    };
  })
);

export function reducer(state: OrderformState | undefined, action: Action) {
  return orderformReducer(state, action);
}
