import { Store } from '@ngrx/store';
import { Observable, of, shareReplay, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  ActionDataAddRequest,
  ActionDataFetchIdRequest,
  ActionDataOptions,
  ActionDataUpdateRequest,
} from './generic.actions';
import {
  GenericApiFetchOptions,
  GenericDataTypeKey,
  GenericDataTypes,
  GenericState,
} from './generic.model';
import { InterfaceGenericeData } from './genericDataInterfaces/base';
import {
  selectGenericDataById,
  selectGenericDataByRefId,
} from './generic.selectors';

/* istanbul ignore next */
export function generateUUID() {
  try {
    return crypto.randomUUID();
  } catch (e) {
    // Public Domain/MIT
    let d = new Date().getTime();
    let d2 = (performance && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16;
      if (d > 0) {
        // eslint-disable-next-line no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // eslint-disable-next-line no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }
}

export const genericDataFetchId = <T extends GenericDataTypes>(
  store: Store<GenericState>,
  dataKey: string,
  dataType: GenericDataTypeKey,
  id: string | number,
  options?: GenericApiFetchOptions
): Observable<T> => {
  store.dispatch(
    new ActionDataFetchIdRequest(dataKey, dataType, '' + id, options)
  );
  return store.select(selectGenericDataById(dataType, '' + id)).pipe(
    filter((idCache) => idCache && idCache.fetch.pending === false),
    switchMap((data) => {
      return data.fetch.error ? throwError(data.fetch.error) : of(<T>data.data);
    })
  );
};

export const genericDataAdd = <T extends GenericDataTypes>(
  store: Store<GenericState>,
  dataKey: string,
  dataType: GenericDataTypeKey,
  item: T,
  options: ActionDataOptions
): Observable<T> => {
  const virtualId = generateUUID();
  store.dispatch(
    new ActionDataAddRequest(dataKey, dataType, item, options, virtualId)
  );

  return store.select(selectGenericDataByRefId(virtualId)).pipe(
    filter((idCache) => idCache && idCache.save.pending === false),
    take(1),
    map((idCache) => {
      if (idCache.save.error) {
        throw idCache.save.error;
      } else {
        return <T>idCache.data;
      }
    })
  );
};

export const genericDataUpdate = <T extends GenericDataTypes>(
  store: Store<GenericState>,
  dataKey: string,
  dataType: GenericDataTypeKey,
  item: T,
  options: ActionDataOptions
): Observable<T> => {
  store.dispatch(new ActionDataUpdateRequest(dataKey, dataType, item, options));

  return store.select(selectGenericDataById(dataType, '' + item.id)).pipe(
    filter(
      (idCache) => idCache && idCache.save && idCache.save.pending === false
    ),
    take(1),
    map((idCache) => {
      if (idCache.save.error) {
        throw idCache.save.error;
      } else {
        return <T>idCache.data;
      }
    })
  );
};
